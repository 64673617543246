import React, { Fragment, Component } from 'react';
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
    Scrolling,
    Summary, TotalItem, Selection
} from 'devextreme-react/data-grid'; //npm i devextreme-react
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { getRequestParams, formatDate, numberGridFormat, isNullOrEmpty } from '../../helpers/globalfunctions.js';
import { LoadPanel } from 'devextreme-react/load-panel';
import { Template } from 'devextreme-react/core/template';
import { currencySymbolFormats, precisionFormats, invoiceStatus } from '../../helpers/fixcodes.js';
import { SelectBox } from 'devextreme-react/select-box';
import { createBrowserHistory } from 'history';
import DateBox from 'devextreme-react/date-box';
import { DocumentsComponent } from '../shared/DocumentsComponent.jsx';
import { InvoicePdfDetailsComponent } from './InvoicePdfDetailsComponent';
import { jsPDF } from 'jspdf';
import Swal from 'sweetalert2'; //npm i sweetalert2 --save
import { exportDataGrid } from 'devextreme/pdf_exporter';
import moment from 'moment';
import axios from 'axios'; //npm install--save axios
import Modal from 'react-modal'; //npm install --save react-modal
import { Tooltip } from 'reactstrap';
const customStyles = {
    content: {
        width: '40%',
    }
}
// const invoiceStatusList = [
//     { id: 1, name: "Approve" }
//     , { id: 2, name: "Send To Customer" }
//     , { id: 3, name: "Send to Tally" }
//     , { id: 5, name: "File" }
//     , { id: 9, name: "Void" }
// ]
const today = new Date();
const currentYear = today.getFullYear();
const startYear = today.getMonth() ? currentYear : currentYear - 1;
const endYear = startYear + 1;
const currentDate = new Date();

const position = { of: '#historydiv' };
const history = createBrowserHistory();


export class InvoiceListComponent extends Component {
    static displayName = InvoiceListComponent.name;
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            draftInvoice: null,
            approveInvoice: null,
            customerList: null,
            invoiceStatusList: null,
            termTypeList: [],
            termTypeId: 0,
            billingStartDate: moment(new Date()).subtract(30, 'day').format("MM/DD/YYYY"),
            billingEndDate: new Date(),
            selectionRowKey: [],
            sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
            invoiceStatusId: 0,
            filterInvoiceStatusId: 0,
            filterCustomerId: 0,
            invoiceTypeList: null,
            filterInvoiceTypeId: 0,
            pdfModelIsOpen: false,
            accountInvoice: null,
            adminAccount: null,
            invoiceDetails: null,
            grossAmount: 0,
            totalAmount: 0,
            taxList: null,
            taxamount: null,
            wordTotalAmount: "",
            invoiceTypeId: 0,
            voidStatus: false,
            emailModelIsOpen: false,
            isSubmittedEmail: false,
            toEmail: "",
            ccEmail: "",
            emailSubject: "",
            financialStartDate: moment().date(1).month(3).year(startYear).format('MM-DD-YYYY'),
            financialEndDate: moment().date(31).month(2).year(endYear).format('MM-DD-YYYY'),
            invoiceAccountDetails: null,
            adminAccount: null,
            invoiceDetails: null,
            grossAmount: 0,
            totalAmount: 0,
            taxList: null,
            taxamount: null,
            wordTotalAmount: "",
            documentSelectedFile: [],
            emailAttachmentId: 0,
            fileGuid: '00000000-0000-0000-0000-000000000000',
            pdfList: [],
            emailCustomerName: "",
            invoiceStatusList: [],
            errors: {
                toEmail: "",
                ccEmail: "",
                emailSubject: "",
            }
        }
    }

    async componentDidMount() {
        await this.GetInvoice();
        await this.getPaymentTermType();
        await this.getCustomerList();
        await this.getInvoiceType();
        await this.getInvoiceStatus();
    }

    getInvoiceStatus = async () => {
        this.setState({
            loadPanelVisible: true
        });
        try {
            const requestParams = getRequestParams("GET", null);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'invoice/getInvoiceStatus', requestParams);
            const data = await response.json();

            this.setState({
                invoiceStatusList: data.payload,
                loadPanelVisible: false
            });
            
        } catch (err) {
            console.log("Error in " + this.displayName + ".getQc function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }
    getCustomerList = async () => {
        this.setState({
            loadPanelVisible: true
        });
        try {
            const requestParams = getRequestParams("GET", null);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'account/dropdownAccount/2', requestParams);
            const data = await response.json();

            this.setState({
                customerList: data.payload,
                loadPanelVisible: false
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getQc function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }
    GetInvoice = async () => {
        this.setState({
            loadPanelVisible: true
        });
        try {
            var request = {};
            request.FromDate = formatDate(this.state.billingStartDate, "yyyy-MM-dd");
            request.ToDate = formatDate(this.state.billingEndDate, "yyyy-MM-dd");
            request.PaymentTermTypeId = parseInt(this.state.termTypeId);
            request.InvoiceId = 0
            request.InvoiceStatusId = parseInt(this.state.invoiceStatusId);
            request.CustomerId = this.state.filterCustomerId;
            request.InvoiceTypeId = this.state.filterInvoiceTypeId;
            request.FinancialFromDate = formatDate(this.state.financialStartDate, "yyyy-MM-dd");
            request.FinancialToDate = formatDate(this.state.financialEndDate, "yyyy-MM-dd");
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'invoice', requestParams);
            const data = await response.json();

            if (data.payload != null) {
                this.setState({
                    draftInvoice: data.payload,
                    loadPanelVisible: false
                });
            }
            else {
                this.setState({
                    draftInvoice: [],
                    approveInvoice: [],
                    loadPanelVisible: false
                })
            }

        } catch (err) {
            console.log("Error in " + this.displayName + ".GetInvoice function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }
    GetVoidInvoice = async () => {
        this.setState({
            loadPanelVisible: true
        });
        try {
            var request = {};
            request.FromDate = formatDate(this.state.billingStartDate, "yyyy-MM-dd");
            request.ToDate = formatDate(this.state.billingEndDate, "yyyy-MM-dd");
            request.PaymentTermTypeId = parseInt(this.state.termTypeId);
            request.InvoiceId = 0
            request.InvoiceStatusId = parseInt(this.state.invoiceStatusId);
            request.CustomerId = this.state.filterCustomerId;
            request.InvoiceTypeId = this.state.filterInvoiceTypeId
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'invoice', requestParams);
            let data = await response.json();
           
            if (data.payload != null) {
                data = JSON.parse(JSON.stringify(data.payload));
                let voidList = data.filter(item => item.invoiceStatusId !== parseInt(invoiceStatus.Void) && item.invoiceStatusId !== parseInt(invoiceStatus.Paid) && item.invoiceStatusId !== parseInt(invoiceStatus.PartiallyPaid) && item.invoiceStatusId !== parseInt(invoiceStatus.Filed));
                this.setState({
                    draftInvoice: voidList,
                    loadPanelVisible: false
                });
            }
            else {
                this.setState({
                    draftInvoice: [],
                    approveInvoice: [],
                    loadPanelVisible: false
                })
            }


        } catch (err) {
            console.log("Error in " + this.displayName + ".GetInvoice function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }
    getPaymentTermType = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'paymentTermType/dropdownType', requestParams);
            const data = await response.json();
            this.setState({
                termTypeList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getPaymentTermType function", err);
        }
    }
   
    getInvoiceType = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'invoice/type', requestParams);
            const data = await response.json();
            this.setState({
                invoiceTypeList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getInvoiceType function", err);
        }
    }

 

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.GetInvoice.bind(this)
            }
        });
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'search',
                Tooltip: "printt data" ,
                onClick: this.GetInvoice.bind(this)
            }
        });
        e.toolbarOptions.items.unshift({
            location: 'before',
            template: 'toolbarTemplate'
        });
    }

    editInvoice(e, data) {
        e.preventDefault();
        history.push({
            pathname: '/invoice/new',
            state: {
                invoiceId: data.id,
                invoiceDate: data.invoiceDate,
                customerId: data.customerId,
                orderNo: data.orderNumber,
                invoiceNo: data.invoiceNo,
                dueDate: data.invoiceDueDate,
                paymentTermTypeId: data.paymentTermTypeId
            }
        });
        window.location.reload(false);
    }
    handleAddInvoice = (e) => {
        sessionStorage.setItem("session_PageName", "Invoice");
        window.location.href = "/invoice/new";
    }

    handleStartDateChanged = (date) => {
        this.setState({ billingStartDate: date.value });
    }
    handleEndDateChanged = (date) => {
        this.setState({ billingEndDate: date.value });
    }
    handlePaymentTermTypeChange = (selectedOption) => {
        this.setState({
            termTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
    }

    handleInvoiceStatusChanges = (selectedOption) => {
        if (selectedOption.selectedItem !== null) {
            this.setState({
                invoiceStatusId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
            });
            setTimeout(() => {
                this.handleInvoiceStatus(selectedOption.selectedItem.id);
            }, 100);
        }
        else {
            this.setState({ invoiceStatusId: 0 })
            setTimeout(() => this.GetInvoice(), 500);
        }

    }
    handleRowPrepared = (e) => {
        if (e.rowType === "data") {
            if (e.data.invoiceStatusId === 11) {
                e.rowElement.style.backgroundColor = '#cdffcd';
                e.rowElement.style.color = '#FF0000';
            }
          }
    }
    
  
    
    handleMultipleSelectionChanged = (e) => {
        this.setState({
            selectionRowKey: e.selectedRowKeys
        });
    }
    handleFinacialStartDateChanged = (date) => {
        this.setState({ financialStartDate: date.value });
    }
    handleFinacialEndDateChanged = (date) => {
        this.setState({ financialEndDate: date.value });
    }
    onExporting = (e) => {
        const doc = new jsPDF();
        if (e.format === "pdf") {
            exportDataGrid({
                jsPDFDocument: doc,
                component: e.component,
                columnWidths: 50
                // indent: 10,
            }).then(() => {
                doc.save('SalesOrder.pdf');
            });
        }
    }
    handleClear = () => {
        this.setState({
            accountId: 0,
            orderDate: "",
            shippingDate: "",
            invoiceNo: "",
            orderNo: "",
            termTypeId: 0,
            billingStartDate: moment(new Date()).subtract(30, 'day').format("MM/DD/YYYY"),
            billingEndDate: new Date(),
            invoiceStatusId: 0,
            filterCustomerId: 0,
            filterInvoiceTypeId: 0,
            voidStatus: false
        });
        setTimeout(() => this.GetInvoice(), 100);
    }

    toolbarItemRender = () => {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-12">
                        <button type="submit" className="btn btn-success" onClick={(e) => this.handleApprovebySchedule(e, 2)} >
                            <span title="Approve"><i className="fas fa-check"></i> Approve</span> </button>
                        {
                            this.state.invoiceStatusId == parseInt(invoiceStatus.Approved) ?
                                <button type="submit" className="btn btn-success" onClick={(e) => this.handleApprovebySchedule(e, 3)} >
                                    <span title="Approve"><i className="fas fa-user-check"></i> Sent To Customer</span> </button> :
                                this.state.invoiceStatusId == parseInt(invoiceStatus.SentToCustomer) ?
                                    <button type="submit" className="btn btn-success " onClick={(e) => this.handleApprovebySchedule(e, 4)} >
                                        <span title="Approve"><i className="fas fa-check"></i> Sent To Tally</span> </button> :
                                    // this.state.invoiceStatusId == parseInt(invoiceStatus.SentToTally) ?
                                    //     <button type="submit" className="btn btn-success" onClick={(e) => this.handleApprovebySchedule(e, 5)} >
                                    //         <span title="Approve"><i className="fas fa-check"></i> Paid</span> </button> :
                                    this.state.invoiceStatusId == parseInt(invoiceStatus.Paid) ?
                                        <button type="submit" className="btn btn-success " onClick={(e) => this.handleApprovebySchedule(e, 8)} >
                                            <span title="Approve"><i className="fas fa-file"></i> File</span> </button> :
                                        this.state.voidStatus == true ?
                                            <button type="submit" className="btn btn-success" onClick={(e) => this.handleApprovebySchedule(e, 9)} >
                                                <span title="Approve"><i className="fas fa-ban"></i> Void</span> </button> :
                                            //         this.state.invoiceStatusId == parseInt(invoiceStatus.Overdue) ?
                                            //             <button type="submit" className="btn btn-success" onClick={(e) => this.handleApprovebySchedule(e, 8)} >
                                            //                 <span title="Approve"><i className="fas fa-check"></i> Void</span> </button> :
                                            //             this.state.invoiceStatusId == parseInt(invoiceStatus.Void) ?
                                            //                 <button type="submit" className="btn btn-success" onClick={(e) => this.handleApprovebySchedule(e, 10)} >
                                            //                     <span title="Approve"><i className="fas fa-check"></i> Void</span> </button> :
                                            //                 this.state.invoiceStatusId == parseInt(invoiceStatus.Filed) ?
                                            //                     <button type="submit" className="btn btn-success " onClick={(e) => this.handleApprovebySchedule(e, 9)} >
                                            //                         <span title="Approve"><i className="fas fa-check"></i> Filed</span> </button> :
                                            ""
                        }
                        {/* if(this.state.invoiceStatusId === parseInt(invoiceStatus.Draft)) */}
                    </div>
                </div>

            </Fragment>
        );
    }
    handleApprovebySchedule = async (e, invoiceStatusId) => {
        e.preventDefault();
        try {
            if (this.state.selectionRowKey != null && this.state.selectionRowKey.length === 0) {
                Swal.fire({
                    icon: 'error',
                    text: "Please select at least one row.",
                }).then((result) => {
                    return;
                });
                return;
            }
            var invoiceMessage;
            if (invoiceStatusId == parseInt(invoiceStatus.Approved)) {
                invoiceMessage = "Are you sure you want to approve selected invoice(s)?"
            }
            else if (invoiceStatusId == parseInt(invoiceStatus.SentToCustomer)) {
                invoiceMessage = "Are you sure you want to send selected invoice(s) to the customer?"
            }
            else if (invoiceStatusId == parseInt(invoiceStatus.SentToTally)) {
                invoiceMessage = "Are you sure you want to send selected invoice(s) to Tally? "
            }
            else if (invoiceStatusId == parseInt(invoiceStatus.Paid)) {
                invoiceMessage = "Are you sure you want to file selected invoice(s)?"
            }
            else {
                invoiceMessage = "Are you sure you want to void selected invoice(s)?"
            }

            Swal.fire({
                html: invoiceMessage,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    this.setState({ loadPanelVisible: true });
                    var UserId = this.state.sessionUser.id
                    const requestParams = getRequestParams('POST', this.state.selectionRowKey);
                    fetch(process.env.REACT_APP_API_BASE_URL + 'invoice/approveInvoice/' + UserId + '/' + parseInt(invoiceStatusId), requestParams)
                        .then(response => { if (response.ok) return response.json() })
                        .then(data => {
                            this.setState({ loadPanelVisible: false });
                            if (data.isSuccess === true) {
                                Swal.fire({
                                    icon: data.isSuccess === true ? 'success' : 'error',
                                    text: data.message,
                                    // text: data.message,
                                }).then((result) => {
                                    if (data.isSuccess === true) {
                                        if (result.isConfirmed) {
                                            this.setState({ voidStatus: false });
                                            this.GetInvoice();
                                            //this.clearData();
                                        }
                                    }
                                })
                            }
                        });
                }
            })
        }
        catch (ex) {
            throw ex;
        }
    }
    handleInvoiceStatus = (data) => {
        //e.preventDefault();
        if (data == 9) {
            this.setState({ invoiceStatusId: 0, voidStatus: true });
            setTimeout(() => this.GetVoidInvoice(), 500);
        }
        else {
            this.setState({ invoiceStatusId: data });
            setTimeout(() => this.GetInvoice(), 500);

        }

    }
    handleFilterCustomerChange = (selectedOption) => {
        this.setState({
            filterCustomerId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0)
        });
    }
    handleInvoiceTypeChange = (selectedOption) => {
        this.setState({
            filterInvoiceTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
    }
    Invoicepdf = (e, data) => {
        e.preventDefault();
        this.setState({ loadPanelVisible: true });
     
        this.refs.invoicePdfDetailsComponent.showModal(data);
        this.setState({ loadPanelVisible: false });

    }

    closeEmailModel = () => {
        this.setState({
            emailModelIsOpen: false,
            toEmail: "",
            ccEmail: "",
            emailSubject: "",
            documentSelectedFile: [],
            errors: {
                toEmail: "",
                ccEmail: "",
                emailSubject: "",
            }
        })
    }

    // SendEmail = async (e) => {
    //     e.preventDefault();
    //     try {
    //         this.setState({ isSubmittedEmail: true });
    //             var request = {};
    //             request.InvoiceId = this.state.emailAttachmentId;
    //             request.ToEmail = this.state.toEmail;
    //             // request.CcEmail = this.state.ccEmail;
    //             request.Subject = this.state.emailSubject;
    //             const requestParams = getRequestParams("POST", request);
    //             const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'invoice/sendMail', requestParams);
    //             const data = await response.json();
    //             this.setState({ isSubmittedEmail: false });
    //             if (data.isSuccess === true) {
    //                 Swal.fire({
    //                     icon: data.isSuccess === true ? 'success' : 'error',
    //                     text: data.message,
    //                     // text: data.message,
    //                 }).then((result) => {
    //                     if (data.isSuccess === true) {
    //                         if (result.isConfirmed) {
    //                             this.closeEmailModel();

    //                         }
    //                     }
    //                 })
    //             }
    //             else {
    //                 this.setState({ isSubmittedEmail: false });
    //                 Swal.fire({
    //                     icon: data.isSuccess === true ? 'success' : 'error',
    //                     text: data.message,
    //                     // text: data.message,
    //                 }).then((result) => {
    //                     if (data.isSuccess === true) {
    //                         if (result.isConfirmed) {
    //                             this.closeEmailModel();

    //                         }
    //                     }
    //                 })
    //             }
    //      } catch (err) {
    //         console.log("Error in " + this.displayName + ".SendEmail function", err);
    //     }
    // }


    handleEmailInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value,

        });
    }
    handleEmailValidation = () => {
        let formIsValid = true;
        try {
            let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let errors = this.state.errors;
            errors.emailSubject = "";
            errors.toEmail = "";
            if (this.state.emailSubject.trim() === "") {
                formIsValid = false;
                errors.emailSubject = "Please enter subject.";
            }
            if (this.state.toEmail === null || this.state.toEmail.trim() === '') {
                formIsValid = false;
                errors.toEmail = "Please enter email.";
            } else if (this.state.toEmail !== '') {
                var entries = this.state.toEmail.split(",");
                entries.forEach(element => {
                    if (!regEmail.test(element)) {
                        formIsValid = false;
                        errors.toEmail = "Please enter valid email.";
                    }
                });
            }
            if (this.state.ccEmail === null || this.state.ccEmail.trim() === '') {
                formIsValid = false;
                errors.ccEmail = "Please enter cc.";
            } else if (this.state.ccEmail !== '') {
                var entries = this.state.ccEmail.split(",");
                entries.forEach(element => {
                    if (!regEmail.test(element)) {
                        formIsValid = false;
                        errors.ccEmail = "Please enter valid email.";
                    }
                });
            }
            this.setState({ errors: errors });
        }
        catch (err) {
            console.log("Error in " + this.displayName + ".handleValidation function", err);
            this.setState({ isSubmited: false })
        }
        return formIsValid;
    }

    emailInvoice = (e, data) => {
        e.preventDefault();
        Swal.fire({
            html: "Do you want to send invoice?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                this.SendEmail(data.id, data.invoiceTypeId, data.customerId, data.orderNumber)
                // this.setState({ emailModelIsOpen: true, toEmail: data.email, emailSubject: "Plexitech E-Bill", emailAttachmentId: data.id, documentSelectedFile: [], emailCustomerName: data.customerName })
            }
        })
    }
    SendEmail = async (id, invoiceTypeId, customerId, orderNo) => {
        //e.preventDefault();
        try {
            this.setState({ loadPanelVisible: true });
            var request = {};
            request.InvoiceId = id;
            request.InvoiceTypeId = invoiceTypeId;
            request.CustomerId = customerId;
            request.SONo = orderNo;
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'invoice/sendMail', requestParams);
            const data = await response.json();
            this.setState({ loadPanelVisible: false });
            if (data.isSuccess === true) {
                Swal.fire({
                    icon: data.isSuccess === true ? 'success' : 'error',
                    text: data.message,
                    // text: data.message,
                }).then((result) => {
                    if (data.isSuccess === true) {
                        if (result.isConfirmed) {
                            // this.closeEmailModel()
                        }
                    }
                })
            }
            else {
                this.setState({ loadPanelVisible: false });
                Swal.fire({
                    icon: data.isSuccess === true ? 'success' : 'error',
                    text: data.message,
                    // text: data.message,
                }).then((result) => {
                    if (data.isSuccess === true) {
                        if (result.isConfirmed) {
                            this.closeEmailModel()
                        }
                    }
                })
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".SendEmail function", err);
        }
    }

    handleInputChange = async (event) => {
        if (this.state.documentTypeId === 0) {
            {
                let formIsValid = true;
                let errors = this.state.errors;
                errors.documentTypeId = "";
                if (this.state.documentTypeId === 0) {
                    formIsValid = false;
                    errors.documentTypeId = "Please select document.";
                }
                this.setState({ errors: errors });
                return formIsValid;
            }
        }

        this.setState({
            loadPanelVisible: true
        });
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (event.target.files.length > 0) {
            this.setState({ documentSelectedFile: event.target.files[0] })
            let myVar = setTimeout(() => this.documentUpload(), 200)
        }
        this.setState({
            loadPanelVisible: false
        });
    }
    documentUpload = async () => {
        if (this.state.documentSelectedFile.size !== undefined) {
            if (this.state.documentSelectedFile.size > 0) {
                const url = process.env.REACT_APP_API_BASE_URL + 'file/uploadFile';
                const formData = new FormData();
                console.log(this.state.documentSelectedFile);
                formData.append('body', this.state.documentSelectedFile);
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data',
                        'UserId': this.state.sessionUser.id,
                        'CustomerName': this.state.emailCustomerName,
                        'Authorization': 'Bearer ' + sessionStorage.getItem('session_token'),
                    },
                };
                this.setState({
                    loadPanelVisible: true
                });
                axios.post(url, formData, config).then((response) => {
                    var resp = response.data;
                    if (resp != null) {
                        this.setState({
                            fileGuid: resp.fileGuid
                        })
                        this.saveDocument(resp)
                    }
                });
                this.setState({
                    loadPanelVisible: false
                });
            }
        }
    }
    saveDocument = async (data) => {
        //
        if (data !== null) {
            this.setState({
                fileGuid: data.fileGuid
            });
            try {
                var isSuccess = false;
                var resultMessage = '';
                var request = new Object();
                request.FileGuid = data.fileGuid;
                request.UserId = Number(this.state.sessionUser.id);
                request.DocumentType = Number(12);
                request.Id = this.state.emailAttachmentId;
                const requestVehicleParams = getRequestParams('POST', request);
                const responseVehicle = await fetch(process.env.REACT_APP_API_BASE_URL + 'file/save-documents', requestVehicleParams);
                const dataVehicle = await responseVehicle.json();
                isSuccess = dataVehicle.isSuccess;
                resultMessage = dataVehicle.message;

                this.setState({
                    loadPanelVisible: false
                });
                this.getDocuments();
            } catch (e) {
                //
            }

        }
    }
    getDocuments = async () => {
        try {
            this.setState({
                loadPanelVisible: true
            });
            const requestParams = getRequestParams('GET', {});
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'file/' + parseInt(12) + '/' + this.state.emailAttachmentId + '/documents', requestParams);
            const data = await response.json();

            var pdfListObj = [];
            if (data.documents != null && data.documents.length > 0) {

                for (var i = 0; i < data.documents.length; i++) {
                    if (data.documents[i].fileGuid != '00000000-0000-0000-0000-000000000000') {
                        const requestParamsImage = getRequestParams('GET', '');
                        const responseImage = await fetch(process.env.REACT_APP_API_BASE_URL + 'file/download/' + data.documents[i].fileGuid, requestParamsImage);
                        const dataImage = await responseImage.json();

                        if (dataImage.fileExtension.toUpperCase() == '.PDF') {
                            var img = new Object();
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            img.fileName = dataImage.fileName;
                            pdfListObj.push(img);
                        }

                    }
                }
            }
            this.setState({
                loadPanelVisible: false,
                pdfList: pdfListObj,

            });

        } catch (e) {
            console.log(e);
        }
    };
    render() {
        const renderGridNumberCell = (data) => {
            // return <div><small><i class={currencySymbolFormats.currencyFormat}></i></small>&nbsp;&nbsp;{parseFloat(data.value).toFixed(precisionFormats[2])}</div>;
            return numberGridFormat(data.value)
        }
        const renderTotalGridNumberCell = (data) => {
            return <div><small><i class={currencySymbolFormats.currencyFormat}></i></small>&nbsp;&nbsp;{parseFloat(data.value).toFixed(precisionFormats[2])}</div>;
        }
        const customizeText = (data) => {
            var value = 'Total: ' + numberGridFormat(data.value);
            return value
            //return `Total - ${data.value.toFixed(precisionFormats[2])}`;
        }
        const customizeGrossText = (data) => {
            var value = 'Total: ' + numberGridFormat(data.value);
            return value
            //return `Total - ${data.value.toFixed(precisionFormats[2])}`;
        }
        const renderGridCell = (data) => {
            return <div className='text-left'>{data.data.invoiceStatusId == parseInt(invoiceStatus.Filed) ? "" : <a href="/#" className="fas fa-pen" title="Edit" onClick={(e) => { this.editInvoice(e, data.data) }} >&nbsp;</a>}
                <a href="/#" className="fas fa-file-invoice" title="Preview" onClick={(e) => { this.Invoicepdf(e, data.data) }} >&nbsp;</a>
                {data.data.invoiceStatusId === parseInt(invoiceStatus.Draft) ? "" : <a href="/#" className="fas fa-envelope" title="Email" onClick={(e) => { this.emailInvoice(e, data.data) }} >&nbsp;</a>}
            </div>;

            {/* <a href="/#" className="fas fa-file-alt" title="Edit" onClick={(e) => { this.printInvoice(e, data.data) }} >&nbsp;</a> */ }
        }
        const renderGridAmountCell = (data) => {
            var temp = data.data.taxAmount + data.data.grossAmount
            var totalAmount = numberGridFormat(data.value);
            return totalAmount
        }
        const renderStatusGridCell = (data) => {

            if (data.data.invoiceStatusId == parseInt(invoiceStatus.Draft)) {
                return <div className='text-left'><span className='badge inv-status-draft border-0'>{data.data.invoiceStatusName}</span></div>;
            }
            else if (data.data.invoiceStatusId == parseInt(invoiceStatus.Approved)) {
                return <div className='text-left'> <button className='badge inv-status-Approve border-0'>{data.data.invoiceStatusName}</button></div>;
            }
            else if (data.data.invoiceStatusId == parseInt(invoiceStatus.SentToTally)) {
                return <div className='text-left'> <button className='badge inv-status-sendToTally border-0'>{data.data.invoiceStatusName}</button></div>;
            }
            else if (data.data.invoiceStatusId == parseInt(invoiceStatus.SentToCustomer)) {
                return <div className='text-left'> <button className='badge inv-status-sendToCustomer border-0'>{data.data.invoiceStatusName}</button></div>;
            }
            else if (data.data.invoiceStatusId == parseInt(invoiceStatus.Paid)) {
                return <div className='text-left'> <button className='badge inv-status-paid border-0'>{data.data.invoiceStatusName}</button></div>;
            }
            else if (data.data.invoiceStatusId == parseInt(invoiceStatus.PartiallyPaid)) {
                return <div className='text-left'> <button className='badge inv-status-partiallyPaid border-0'>{data.data.invoiceStatusName}</button></div>;
            }
            else if (data.data.invoiceStatusId == parseInt(invoiceStatus.Overdue)) {
                return <div className='text-left'> <button className='badge inv-status-Approve border-0'>{data.data.invoiceStatusName}</button></div>;
            }
            else {
                return <div className='text-left'> <button className='badge inv-status-Approve'>{data.data.invoiceStatusName}</button></div>;
            }
        }
        return (
            <Fragment>
                <div className="section-body">
                    <div className='card bg-filter'>
                        <div className='card-body'>
                            <div className="section-header">
                                <div className='row'>
                                    <div className='col-lg-4 col-md-3 col-sm-12'>
                                        <div className="form-group">
                                            <label htmlFor="">Customer</label>
                                            <SelectBox
                                                dataSource={this.state.customerList}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select customer"
                                                name="id"
                                                defaultValue={0}
                                                value={this.state.filterCustomerId}
                                                displayExpr='name'
                                                valueExpr='id'
                                                onSelectionChanged={this.handleFilterCustomerChange}
                                                showClearButton={true}
                                                searchEnabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label htmlFor="">Invoice Type</label>
                                            <SelectBox
                                                dataSource={this.state.invoiceTypeList}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select invoice type"
                                                name="id"
                                                defaultValue={0}
                                                value={this.state.filterInvoiceTypeId}
                                                displayExpr='name'
                                                valueExpr='id'
                                                onSelectionChanged={this.handleInvoiceTypeChange}
                                                showClearButton={true}
                                                searchEnabled={true}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-lg-2 col-md-2 col-sm-12'>
                                        <div className="form-group">
                                            <label htmlFor="">Payment Term</label>
                                            <SelectBox
                                                dataSource={this.state.termTypeList}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select payment term"
                                                name="id"
                                                defaultValue={0}
                                                value={this.state.termTypeId}
                                                displayExpr='name'
                                                valueExpr='id'
                                                onSelectionChanged={this.handlePaymentTermTypeChange}
                                                showClearButton={true}
                                                searchEnabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-2 col-md-2 col-sm-12'>
                                        <div className="form-group">
                                            <label htmlFor="">Invoice Status</label>
                                            <SelectBox
                                                dataSource={this.state.invoiceStatusList}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select invoice status"
                                                name="id"
                                                defaultValue={0}
                                                value={this.state.invoiceStatusId}
                                                displayExpr='name'
                                                valueExpr='id'
                                                onSelectionChanged={this.handleInvoiceStatusChanges}
                                                showClearButton={true}
                                                searchEnabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-2 col-md-2 col-sm-12'>
                                        <div className="form-group">
                                            <label htmlFor="">Invoice Start Date</label>
                                            <DateBox type="date"
                                                className="replay-date-time"
                                                name="billingStartDate"
                                                placeholder="dd/mm/yyyy"
                                                displayFormat="dd/MM/yyyy"
                                                showAnalogClock={true}
                                                useMaskBehavior={true}
                                                defaultValue="dd/MM/yyyy"
                                                value={this.state.billingStartDate}
                                                onValueChanged={this.handleStartDateChanged}
                                                width="100%"
                                                acceptCustomValue={false}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-2 col-md-2 col-sm-12'>
                                        <div className="form-group">
                                            <label htmlFor="">Invoice End Date</label>
                                            <DateBox type="date"
                                                className="replay-date-time"
                                                name="billingEndDate"
                                                placeholder="dd/mm/yyyy"
                                                displayFormat="dd/MM/yyyy"
                                                showAnalogClock={true}
                                                useMaskBehavior={true}
                                                defaultValue="dd/MM/yyyy"
                                                value={this.state.billingEndDate}
                                                onValueChanged={this.handleEndDateChanged}
                                                width="100%"
                                                acceptCustomValue={false}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className='col-lg-2 col-md-2 col-sm-12'>
                                        <div className="form-group">
                                            <label>Financial Start Date </label>
                                            <DateBox type="date"
                                                className="start-date-time"
                                                name="financialStartDate"
                                                placeholder="dd/mm/yyyy"
                                                displayFormat="dd/MM/yyyy"
                                                showAnalogClock={true}
                                                useMaskBehavior={true}
                                                defaultValue="dd/MM/yyyy"
                                                value={this.state.financialStartDate}
                                                onValueChanged={this.handleFinacialStartDateChanged}
                                                width="100%"
                                                // disabled={this.state.salesorderId > 0 ? true : false}
                                                acceptCustomValue={false}
                                            />
                                        </div>
                                    </div> */}
                                    {/* <div className='col-lg-2 col-md-2 col-sm-12'>
                                        <div className="form-group">
                                            <label>Financial End Date </label>
                                            <DateBox type="date"
                                                className="end-date-time"
                                                name="financialEndDate"
                                                placeholder="dd/mm/yyyy"
                                                displayFormat="dd/MM/yyyy"
                                                showAnalogClock={true}
                                                useMaskBehavior={true}
                                                defaultValue={this.state.financialEndDate}
                                                value={this.state.financialEndDate}
                                                onValueChanged={this.handleFinacialEndDateChanged}
                                                width="100%"
                                                acceptCustomValue={false}
                                            />
                                        </div>
                                    </div> */}
                                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label htmlFor="">&nbsp;</label><br />
                                            <button type="submit" className="btn btn-success" onClick={this.GetInvoice}>
                                                Search
                                            </button>&nbsp;&nbsp;
                                            <button type="submit" className="btn btn-outline-secondary" onClick={this.handleClear}>
                                                Clear
                                            </button>&nbsp;&nbsp;
                                            <button type="submit" className="btn btn-success" onClick={this.handleAddInvoice}>
                                                New
                                            </button>&nbsp;&nbsp;
                                            {/* <button type="submit" className="btn btn-success" onClick={this.handleAddInvoice}>
                                                Approve
                                            </button>&nbsp;&nbsp; */}

                                            {/* <span className="btn-group inv-status-btn-grp"> */}
                                            {/* <button type="button" className="btn btn-success">Select Action</button>
                                                <button type="button" title="More" className="btn  btn-success dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false">
                                                    <i className="fas fa-ellipsis-h"></i>
                                                </button> */}
                                            {/* <div className="dropdown-menu" role="menu">
                                                    <a className="dropdown-item" href="#" onClick={(e) => this.handleInvoiceStatus(e, 1)}><i className="fas fa-check"></i> Appprove</a>
                                                    <a className="dropdown-item" href="#" onClick={(e) => this.handleInvoiceStatus(e, 2)}><i className="fas fa-user-check"></i> Send To Customer</a>
                                                    <a className="dropdown-item" href="#" onClick={(e) => this.handleInvoiceStatus(e, 3)}><i className="fas fa-file-import"></i> Send to Tally</a>
                                                    <a className="dropdown-item" href="#" onClick={(e) => this.handleInvoiceStatus(e, 5)}><i className="fas fa-file"></i> File</a>
                                                    <a className="dropdown-item" href="#" onClick={(e) => this.handleInvoiceStatus(e, 9)}><i className="fas fa-ban"></i> Void</a>
                                                </div> */}
                                            {/* </span> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='card'>
                        <div className='card-body'>
                            <DataGrid
                                dataSource={this.state.draftInvoice}
                                keyExpr="id"
                                //id='gridSalesOrderList'
                                columnsAutoWidth={false}
                                showColumnLines={true}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={false}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                //selectedRowKeys={this.state.selectionRowKey}
                                onRowPrepared={this.handleRowPrepared}
                                onSelectionChanged={this.handleMultipleSelectionChanged}
                                onExporting={this.onExporting}
                                width="100%"
                                onToolbarPreparing={this.onToolbarPreparing}>
                                <Selection
                                    mode="multiple"
                                    selectAllMode={"allPages"}
                                    showCheckBoxesMode={"always"}

                                />
                                <Column dataField="id" width={70} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                <Column dataField="invoicestatus" width={130} caption="Invoice Status" allowFiltering={false} allowSorting={false} cellRender={renderStatusGridCell} />
                                <Column dataField="invoiceNo" caption="Invoice#" allowSearch={true} allowFiltering={false} allowSorting={true} width={130} />
                                <Column dataField="invoiceTypeName" caption="Invoice Type" allowSearch={true} allowFiltering={false} allowSorting={true} width={130} />

                                <Column dataField="invoiceDate" caption="Invoice Date" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} dataType="datetime" format="dd/MM/yyyy" />
                                <Column dataField="customerName" caption="Customer" allowSearch={true} allowFiltering={false} allowSorting={true} width={420} />
                                <Column dataField="referenceSono" caption="PO No" allowSearch={true} allowFiltering={false} allowSorting={true} width={110} />
                                <Column dataField="referenceName" caption="Reference Name" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} />
                                <Column dataField="qty" caption="QTY" allowSearch={true} allowFiltering={false} allowSorting={true} width={80} />
                                {/*                                  
                                    <Column dataField="qty" caption="QTY" allowSearch={true} allowFiltering={false} allowSorting={true} width={80} />
                                    <Column dataField="taxAmount" caption="Total Tax" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} cellRender={renderTotalGridNumberCell} /> */}
                                <Column dataField="grossAmount" caption="Taxable Amount" allowSearch={true} allowFiltering={false} allowSorting={true} width={140}
                                    format={"#,##0.###"}
                                    cellRender={renderGridNumberCell}

                                />
                                {/* <Column dataField="amountDue" caption="Amount Due" allowSearch={true} allowFiltering={false} allowSorting={true} width={110} cellRender={renderGridAmountCell} /> */}
                                <Column dataField="totalAmount" caption="Total Amount" allowSearch={true} allowFiltering={false} allowSorting={true} width={110} cellRender={renderGridAmountCell} />
                                {/* <Column dataField="shippingDate" caption="Shipped Date" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} dataType="datetime" format="dd/MM/yyyy HH:mm" /> */}

                                <Summary>
                                    <TotalItem column="grossAmount" summaryType="sum" customizeText={customizeGrossText} />
                                    <TotalItem column="taxAmount" summaryType="sum" customizeText={customizeText} />
                                </Summary>
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={false} />
                                <Scrolling showScrollbar="always" />
                                <Template name="toolbarTemplate" render={this.toolbarItemRender} />
                                <HeaderFilter visible={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                <Export enabled={true} allowExportSelectedData={false} fileName="Invoices" />
                                
                            </DataGrid>
                        </div>
                    </div>
                </div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.emailModelIsOpen}
                    style={customStyles}
                    className={"react-modal"}
                    //s  onAfterOpen={this.afterOpenDocumentModal}
                    onRequestClose={this.closeEmailModel}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="Post" onSubmit={this.SendEmail} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            {/*<h6 className="modal-title">{this.state.pageName} - {this.state.displayNumber}</h6>*/}
                            <h6 className="modal-title">
                            </h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeEmailModel}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">To <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="toEmail" value={this.state.toEmail} disabled={true} placeholder="e.g. user@domain.com" title="Email" onChange={this.handleEmailInputChange} />
                                        {this.state.errors["toEmail"].length > 0 && <span className="error invalid-feedback">{this.state.errors["toEmail"]}</span>}
                                    </div>
                                </div>
                                {/* <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">CC <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="ccEmail" value={this.state.ccEmail} placeholder="e.g. user@domain.com" maxLength="50" title="Email" onChange={this.handleEmailInputChange} />
                                        {this.state.errors["ccEmail"].length > 0 && <span className="error invalid-feedback">{this.state.errors["ccEmail"]}</span>}
                                    </div>
                                </div> */}
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label htmlFor="">Subject <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control form-control-sm" name="emailSubject" value={this.state.emailSubject} disabled={true} placeholder="Enter Subject" title="Subject" onChange={this.handleEmailInputChange} />
                                        </div>

                                    </div>
                                </div>

                                <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                                    <div className="form-group">
                                        <label>Add Document</label>
                                        <div className="input-group">
                                            <div className="custom-file">
                                                <input type="file" name="driverFile" className="custom-file-input" onChange={this.handleInputChange} />
                                                <label className="custom-file-label">Choose file</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">

                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="box-body no-padding">
                                        <div>
                                            <ul className="list-group list-group-horizontal">

                                                {this.state.pdfList.length > 0 && this.state.pdfList.map((item, j) =>
                                                    <li className='list-group-item' style={{ position: "relative" }} key={j}>
                                                        <a href={item.src} download={item.fileName}><div className="framehover"><img src="../../assets/img/pdf_icon.png" width="150" alt="Blank Image" /></div></a>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success" onClick={this.SendEmail} >
                                {this.state.isSubmittedEmail && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmittedEmail && <span title="Submit">Submit</span>}
                            </button>
                            <button type="button" className="btn btn-outline-secondary" onClick={this.closeEmailModel} title="Cancel">Cancel</button>
                        </div>
                        {/* <ImgsViewer
                        imgs={this.state.imageList}
                        currImg={this.state.currImg}
                        onClickPrev={this.gotoPrev}
                        onClickNext={this.gotoNext}
                        onClose={this.closeViewer}
                        isOpen={this.state.isOpen}
                       // onClose={this.closeImgsViewer}
                    /> */}
                    </form>
                </Modal>

                <InvoicePdfDetailsComponent ref="invoicePdfDetailsComponent" />
                <DocumentsComponent ref="documentsComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    message="Please, wait..."
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    hideOnOutsideClick={false}
                />
            </Fragment>
        )
    };
}