import React, { Fragment, Component } from 'react';
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
} from 'devextreme-react/data-grid'; //npm i devextreme-react
import Modal from 'react-modal'; //npm install --save react-modal;
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { exportFormats,documentType } from '../../helpers/fixcodes.js';
import { LoadPanel } from 'devextreme-react/load-panel';
import { DocumentsComponent } from '../shared/DocumentsComponent.jsx';
import { jsPDF } from 'jspdf';
import { exportDataGrid } from 'devextreme/pdf_exporter';

const position = { of: '#historydiv' };
 
const customStyles = {
    content: {
        width: '30%',
    }
}

export class CustomerComponent extends Component {
    static displayName = CustomerComponent.name;
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            accountList: [],
            customerConfigurationList:[],
            addressModelIsOpen: false,
            billingAddress: "",
            shippingAddress: ""
        }
    }

    async componentDidMount() {

        this.setState({
            loadPanelVisible: true
        });
        await this.getAccounts();
        this.setState({
            loadPanelVisible: false
        });
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getAccounts.bind(this)
            }
        });
    }
    getAccounts = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'account/account/0/2', requestParams);            
            const data = await response.json();
            this.setState({
                accountList: data.account,
                customerConfigurationList: data.contractConfiguration !== null ? data.contractConfiguration : []
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getAccounts function", err);
        }
        console.log(JSON.stringify(this.state.data, null, 2))
    }
    redirectNewcustomer = (e) => {
        e.preventDefault();
        sessionStorage.setItem("session_PageName", "Master • Customers")
        this.props.history.push({
            pathname: '/addaccount',
            state: { AccountTypeName: "Customer", ModuleTypeName: "Customer", DocumentTypeId: documentType.Customer, sourceUrl: "/customer" }
        })
        window.location.reload(false);
    }
    showAddress = (e, data) => {
        e.preventDefault();
        this.setState({
            addressModelIsOpen: true,
            billingAddress: data.billingAddress1 + ' ' + data.billingAddress2 + ' ' + data.billingCity + ' ' + data.billingState + ' ' + data.billingPostalCode ,
            shippingAddress: data.shippingAddress1 + ' ' + data.shippingAddress2 + ' ' + data.shippingCity + ' ' + data.shippingState + ' ' +data.shippingPostalCode
        })
    }
    closeModel = () => {
        this.setState({
            addressModelIsOpen: false,
        })
    }
    openTaxCofigurationModel = (e) => {
        e.preventDefault();
        sessionStorage.setItem("session_PageName", "Tax Configuration");
        window.location.href = "/taxconfiguration";
    }
    openContractCofigurationModel = (e) => {
        e.preventDefault();
        sessionStorage.setItem("session_PageName", "Customer Contract");
        window.location.href = "/contract";
    }
    editCustomerModel = (e, Griddata) => {
      console.log("ranjit" & JSON.stringify(Griddata, null, 2))
        e.preventDefault();
        this.props.history.push({
            pathname: '/editaccount',
            state: { AccountTypeName: "Customer", data: Griddata, sourceUrl: "/customer", documentTypeId: 6,  customerConfiguration: "" }
        });
        window.location.reload(false);
    }
    handleOnViewUploadDocument = async (e, data) => {
        e.preventDefault();
        try {
            var documentArray = []
            let row = {};
            row.id = data.id === null ? 0 : Number(data.id);
            row.customerName = data.name === null ? '' : data.name.trim();
            documentArray.push(row);
            this.refs.documentsComponent.showModal('Customer Document', documentArray, data.name);
        } catch (ex) {
            console.log(ex);
        }
    }
    onExporting = (e) => {
        const doc = new jsPDF();
        if (e.format === "pdf") {
            exportDataGrid({
                jsPDFDocument: doc,
                component: e.component,
                columnWidths: 50
                // indent: 10,
            }).then(() => {
                doc.save('Customer.pdf');
            });
        }
    }
    render() {
        const renderGridCell = (data) => {
            return <div>
                <a href="/#" className="fas fa-map-marker-alt" title="Address" onClick={(e) => { this.showAddress(e, data.data) }} >&nbsp;</a>
            </div>;
        }
        const renderEditGridCell = (data) => {            
            return <div>
                <a href="/#" className="fas fa-pen" title="Edit" onClick={(e) => { this.editCustomerModel(e, data.data) }} >&nbsp;</a>
                <a href="/#" className="fas fa-file-alt" title="Document(s)" onClick={(e) => { this.handleOnViewUploadDocument(e, data.data) }} >&nbsp;</a>
            </div>;
        }
        return (
            <Fragment>
                <div className="section-body">
                    <div className='card bg-filter'>
                        <div className='card-body'>
                            <div className="section-header">
                                <h6 class="m-0">Customer</h6>
                                <div className="section-header-buttons">
                                    <button type="submit" className="btn btn-success" onClick={this.redirectNewcustomer} title='New'>
                                        New
                                    </button>&nbsp;&nbsp;
                                    <button type="submit" className="btn btn-success" onClick={this.openTaxCofigurationModel}>
                                        <span title="Tax Configuration"><i className="fas fa-percentage"></i> Tax Configuration</span>
                                    </button>&nbsp;&nbsp;
                                    {/*<button type="submit" className="btn btn-outline-secondary" onClick={this.openTaxCofigurationModel}>*/}
                                    {/*        <span title="Tax Configuration"><i className="fas fa-percentage"></i> Tax Configuration</span>*/}
                                    {/*    </button>&nbsp;&nbsp;*/}
                                    <button type="submit" className="btn btn-success" onClick={this.openContractCofigurationModel}>
                                        <span title="Contract Configuration"><i className="fas fa-file-signature"></i> Contract Configuration</span>
                                    </button>
                                    {/* <a href="#" className="btn btn-outline-secondary" title='Filter'><i className="fas fa-filter"></i></a>
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-icon" title="More" data-toggle="dropdown" aria-expanded="false">
                                            <i className="fas fa-ellipsis-h"></i>
                                        </button>
                                        <div className="dropdown-menu" role="menu">
                                            <a className="dropdown-item" href="/#"><i className="fas fa-file-import"></i> Import</a>
                                            <a className="dropdown-item" href="/#"><i className="fas fa-file-import"></i> Export</a>
                                            <a className="dropdown-item" href="/#"><i className="fas fa-print"></i> Print</a>
                                            <a className="dropdown-item" href="/#"><i className="fas fa-envelope"></i> Email</a>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div className='card'>
                    <div className='card-body'>
                                <DataGrid
                                    dataSource={this.state.accountList}
                                    keyExpr="id"
                                    selection={{ mode: 'single' }}
                                    columnsAutoWidth={false}
                                    showColumnLines={true}
                                    showRowLines={true}
                                    showBorders={true}
                                    rowAlternationEnabled={false}
                                    allowColumnResizing={true}
                                    columnResizingMode='widget'
                                    onExporting={this.onExporting}
                                    onToolbarPreparing={this.onToolbarPreparing}>
                                    <Column dataField="id" width={47} caption="" allowFiltering={false} allowSorting={false} cellRender={renderEditGridCell} />
                                    <Column dataField="name" caption="Name" allowSearch={true} allowFiltering={false} allowSorting={true} width={400} />
                                    <Column dataField="email" caption="Email" allowSearch={true} allowFiltering={false} allowSorting={true} width={250} />
                                    <Column dataField="phone1" caption="Phone" allowSearch={true} allowFiltering={false} allowSorting={true} width={120} />
                                    <Column dataField="website" caption="Website" allowSearch={true} allowFiltering={false} allowSorting={true} width={200} />
                                    <Column dataField="" caption="Address" allowSearch={true} allowFiltering={false} allowSorting={true} width={80} cellRender={renderGridCell} />
                                    <Column dataField="contactPerson" caption="Contact Person" allowSearch={true} allowFiltering={false} allowSorting={true} width={200} />
                                    <SortByGroupSummaryInfo summaryItem="count" />
                                    <GroupPanel visible={false} />
                                    <HeaderFilter visible={true} />
                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                    <Grouping autoExpandAll={true} />
                                    <Paging defaultPageSize={20} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                    <Export enabled={true}  allowExportSelectedData={false} fileName="Customer" />
                                </DataGrid>
                            </div>
                    </div>
                </div>
                <Modal ariaHideApp={false}
                  //  isOpen={this.state.addressModelIsOpen}
                    style={customStyles}
                    className={"react-modal"}
                    onRequestClose={this.closeModel}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Address</h6>
                        </div>
                        <div className="modal-body">
                            <div className='row mt-2'>
                                <div className='col-6'>
                                    <div className='bg-body-tertiary p-3 rounded'>
                                        <div className='font-weight-bold mb-2'>Billing Address</div>
                                        <span className='text-wrap'>{this.state.billingAddress}</span>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='bg-body-tertiary p-3 rounded'>
                                        <div className='font-weight-bold mb-2'>Shipping Address</div>
                                        <span className='text-wrap'>{this.state.shippingAddress}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary" onClick={this.closeModel} title="Cancel">Cancel</button>
                        </div>
                    </form>
                </Modal>
                <DocumentsComponent ref="documentsComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    hideOnOutsideClick={false}
                />                
            </Fragment>
        )
    };
}