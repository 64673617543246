// export const DateFormat = {
//     DateTime24WesternFormat: "MM/dd/yyyy HH:mm",
//     DateTime12WesternFormat: "MM/dd/yyyy hh:mm",
//     DateTime24WesternLongFormat: "MM/dd/yyyy HH:mm:ss",
//     DateWesternFormat: "MM/dd/yyyy",

//     DateTime24NorthFormat: "dd/MM/yyyy HH:mm",
//     DateTime12NorthFormat: "dd/MM/yyyy hh:mm",
//     DateTime24NorthLongFormat: "dd/MM/yyyy HH:mm:ss",
//     DateNorthFormat: "dd/MM/yyyy",

//     Time24Format: "HH:mm",
//     Time12Format: "hh:mm",
//     Time24FormatLong: "HH:mm:ss",
//     Time24FormatLongAMPM: "hh:mm:ss a",
// };

export const DateFormat = {
    DateTime24Format: "dd/MM/yyyy HH:mm",
    DateTimeFullFormat: "dd/MM/yyyy HH:mm:ss",
    DateTimeFullYMDFormat: "yyyy/MM/dd HH:mm:ss",
    DateFormat: "dd/MM/yyyy",
    DateFormatMonthName: "dd-MMM-yyyy",
    TimeFormat: "HH:mm",
    DayMonthDateFormat: "Do MMM",
    DayMonthYYDateFormat: "Do MMM YY",
    DayMonthYearDateFormat: "Do MMM YYYY",
    DayMonthYYTimeDateFormat: "Do MMM YY h:mm:ss a",
    DayMonthYearTimeDateFormat: "Do MMM YYYY h:mm:ss a",
    DateFormatMMddyyyy: "MM/dd/yyyy",
    TimeFormatHHmmss: "HH:mm:ss",
  
    MomentDisplayDateTime24Format: "DD/MM/yyyy HH:mm",
    MomentDisplayDateTime24MonthNameFormat: "DD/MMM/yyyy HH:mm",
    MomentDisplayDateTimeAMPMFullFormat: "DD/MM/yyyy hh:mm A",
    MomentDisplayDateTimeFullFormat: "DD/MM/yyyy hh:mm:ss",
    MomentDisplay24DateTimeFullFormat: "DD/MM/yyyy HH:mm:ss",
    MomentDisplayDateFormat: "DD/MM/yyyy",
    MomentDisplayTimeFormat: "HH:mm",
    MomentDateFormat: "YYYY-MM-DDThh:mm:ss",
  
    SqlDateTime24Format: "YYYY-MM-DD HH:mm",
    SqlDateTimeFullFormat: "YYYY-MM-DD HH:mm:ss",
    SqlDateFormat: "YYYY-MM-DD",
    SqlTimeFormat: "HH:mm",
    SqlStartDateFormat: "YYYY-MM-DD 00:00:00",
    SqlEndDateFormat: "YYYY-MM-DD 23:59:59",
  
    GridDateFormat: "dd/MM/yyyy",
    GridDateTimeHHmmFormat: "dd/MM/yyyy HH:mm",
    GridDateTimeHHmmssFormat: "dd/MM/yyyy HH:mm:ss",
    GridDateTimeAMPMFormat: "dd/MM/yyyy h:mm:ss a",
    GridTimeHHmmFormat: "HH:mm",
    GridTimeHHmmssFormat: "HH:mm:ss",
    GridTimeAMPMFormat: "h:mm:ss a",
  };
  
  

export const OrderStatus = {
    PENDING: 1,
    COMPLETED: 2
}

export const QCResultStatus = {
    REPAIRED: 1,
    REPLACED: 2,
    REFUNDED: 3,
    PENDING: 4,
    FAILED: 5,
    PASSED: 6
}

export const exportFormats = [
    'pdf','xlsx'
];

export const symbolFormats = {
    percentageFormat: "fas fa-percentage",
}

export const currencySymbolFormats = {
    currencyFormat: "",
}

export const precisionFormats = {
    0:"0",
    1:"1",
    2:"2",
    3:"3",
}

export const orderType = {
    GoodsCapex: 1,
    Serviceopex: 2
}
export const documentType = {
    SalesOrder:	1,
    PurchaseOrder:2,
    Invoice	:3,
    SalesReturn	:4,
    PurchaseReturn:5,
    Customer:6,
    Vendor:7,
    Courier:8,
    CreditNote:	9,
    DebitNote:10,
    Bills:1002,
    Bills:1003,
    
}
export const invoiceStatus = {
    Draft:1,
    Approved:2,
    SentToCustomer	:3,
    SentToTally:4,
    Paid:5,
    PartiallyPaid:6,
    Overdue:7,
    Filed:8,
    Void:9,
}
export const approvedBy ={
    Appproved:1
}